<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div v-if="selectedView === '1'" key="1">
        <!-- <v-flex class="mt-5">
          <h1 class="mb-6">
            Landlords
          </h1>
          <v-spacer />
        </v-flex> -->
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              class="ml-15"
              dark
              color="green"
              @click="selectedView = '2'; generateString()"
            >
              New Landlord
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="landlords"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          />
        </v-card>
      </div>
      <div v-else key="2">
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <h3>
              Add Landlord
            </h3>
            <v-spacer />
            <v-btn
              class="ml-15"
              dark
              color="orange"
              @click="selectedView = '1'; linkToBeSent='https://dev.homevision.se/landlord-registration/'"
            >
              Go back
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="mb-3 text-h6">
                Details
              </div>
              <v-text-field
                v-model="name"
                :counter="40"
                :rules="nameRules"
                label="Name"
                required
              />

              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              />

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                required
              />
              <div class="mb-3 text-h6">
                Address
              </div>
              <v-row>
                <v-text-field
                  v-model="address.street"
                  class="ml-3"
                  :rules="addressRules"
                  label="Address"
                  required
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="address.city"
                  class="ml-3"
                  :rules="addressRules"
                  label="City"
                  required
                />
                <v-text-field
                  v-model="address.postCode"
                  class="pl-5 pr-3"
                  :rules="addressRules"
                  label="Postcode"
                  required
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="address.country"
                  class="ml-3"
                  :rules="addressRules"
                  label="Country"
                  disabled
                  required
                />
              </v-row>

              <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'You must agree to continue!']"
                label="Are you sure? You are creating new landlord!"
                required
              />

              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid || !name || !email || !phone"
                @click="addLandlord"
              >
                <div v-if="btnLoading">
                  <v-progress-circular
                    indeterminate
                    color="white"
                  />
                </div>
                <div v-else>
                  Submit
                </div>
              </v-btn>
            </v-form>
            <v-text-field
              v-model="linkToBeSent"
              class="pt-10"
              disabled
            />
          </v-card-text>
        </v-card>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
const uuid = require('uuid')
export default {
  name: 'Landlords',
  components: {
  },
  data () {
    return {
      selectedView: '1',
      showProgress: false,
      btnLoading: false,
      pagination: {},
      search: '',
      landlords: [],
      headers: [
        { text: '#', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Active', value: 'active' }
      ],
      footerProps: {
        'items-per-page-options': [30, 50, 90, 120]
      },
      valid: false,
      linkToBeSent: 'https://dev.homevision.se/landlord-registration/',
      regToken: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 10) || 'Name must be at least 10 characters',
        v => (v && v.length <= 40) || 'Name must be less than 40 characters'
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (v && v.length >= 10) || 'Name must be at least 9 digits'
      ],
      address: {
        street: '',
        houseNumber: '',
        city: '',
        postCode: '',
        country: 'Sverige'
      },
      addressRules: [
        v => !!v || 'Address is required'
      ],
      checkbox: false
    }
  },
  watch: {
  },
  mounted () {
    this.loadLandlords()
  },
  methods: {
    async loadLandlords () {
      this.showProgress = true
      this.$store.dispatch('getLandlords').then(response => {
        this.landlords = response.data
        this.showProgress = false
      })
    },
    generateString () {
      const id = uuid.v4()
      this.linkToBeSent = this.linkToBeSent + id
      this.regToken = id
    },
    addLandlord () {
      this.$refs.form.validate()
      if (this.valid) {
        this.btnLoading = true
        const landlordDetails = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          regToken: this.regToken
        }
        this.$store.dispatch('addLandlord', landlordDetails).then(response => {
          this.showProgress = true
          this.btnLoading = false
          this.selectedView = '1'
          this.$store.dispatch('getLandlords').then(response => {
            this.landlords = response.data
            this.showProgress = false
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
